import {Component, OnInit, Inject} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {ContactsService} from '../../../../../shared/services/contacts/contacts.service';
import {Const_countries} from '../../../../../shared/constants';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {takeUntil, map, startWith} from 'rxjs/operators';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SocketService} from 'app/shared/services/socket.service';

@Component({
    selector: 'app-pay-inform',
    templateUrl: './pay-inform.component.html',
    styleUrls: ['./pay-inform.component.scss']
})
export class PayInformComponent implements OnInit {

    form: UntypedFormGroup;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    countryList: any;
    userBalance = 0;
    balanceRefresh = true;
    sendingBalance = 0;
    isPaymentAccepted:Boolean = false
    filteredOptions: Observable<any[]>;

    @BlockUI() blockUI: NgBlockUI;
    private _unsubscribeAll: Subject<any>;

    constructor(
        public matDialogRef: MatDialogRef<PayInformComponent>,
        public _matDialog: MatDialog,
        private contactService: ContactsService,
        private matSnackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private socketService: SocketService,
        @Inject(MAT_DIALOG_DATA) public selectedPayInfo: any,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.isPaymentAccepted = this.selectedPayInfo?.isFlagged;
        this.countryList = Const_countries;
        this.form = this._formBuilder.group({
            firstName: [this.selectedPayInfo.firstName ?? '', Validators.required],
            lastName: [this.selectedPayInfo.lastName ?? '', Validators.required],
            address: [this.selectedPayInfo.address ?? '', Validators.required],
            city: [this.selectedPayInfo.city ?? '', Validators.required],
            state: [this.selectedPayInfo.state ?? '', Validators.required],
            postalCode: [this.selectedPayInfo.postalCode ?? '', Validators.required],
            payment: [this.selectedPayInfo.payment ?? '', Validators.required],
            country: [this.selectedPayInfo.country ? this.getCountryNameByCode(this.selectedPayInfo.country) : '', Validators.required],
            accountId: [this.selectedPayInfo.sendTo ?? '', Validators.required],
            fromCash: [this.selectedPayInfo.fromCash ?? 0, Validators.required],
            toCash: [
                {
                    value: this.selectedPayInfo.toCash ?? 0,
                    disabled: true
                }, Validators.required
            ],
            transactionID: [this.selectedPayInfo.transactionID ?? 0, Validators.required],
        });

        if (this.isPaymentAccepted) {
            this.form.disable();
        }
        
        
        this.sendingBalance = this.selectedPayInfo.fromCash ?? 0;
        this.filteredOptions = this.form.controls['country'].valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
        this.contactService.getBalanceFromNN(this.selectedPayInfo.owner._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(balanceInfo => {
                this.balanceRefresh = false;
                if (balanceInfo['success']) {
                    this.userBalance = balanceInfo['data']['balance'];
                } else if (balanceInfo['error']) {
                    this.matSnackBar.open(balanceInfo['error'], '', {
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center',
                        politeness: 'assertive',
                        duration: 5000
                    });
                }
            }, err => {

            });
    }

    payout(payId = '') {

        if (!this.form.valid) {
            return;
        }
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to pay?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.blockUI.start('Sending...');
                this.contactService.acceptPayout(
                    {
                        id: this.selectedPayInfo.id,
                        param: {
                            firstName: this.form.value.firstName,
                            lastName: this.form.value.firstName,
                            address: this.form.value.firstName,
                            state: this.form.value.firstName,
                            city: this.form.value.firstName,
                            postalCode: this.form.value.firstName,
                            country: this.getCountryCodeByName(this.form.value.country),
                            sendTo: this.form.value.accountId,
                            payment: this.form.value.payment,
                            fromCash: this.form.value.fromCash,
                            toCash: this.form.value.toCash,
                            transactionID: this.form.value.transactionID
                        }
                    }
                ).pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(data => {
                        if (data['success']) {
                            this.matSnackBar.open(data['data']['msg'], '', {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                politeness: 'assertive',
                                duration: 5000
                            });
                            this.blockUI.stop();
                            this.matDialogRef.close({success: 1, result: data['data'].result.updatedPayInfo});
                            this.socketService.emitData('sendNotificationReport', data['data'].result.reportParam);
                        } else {
                            this.matSnackBar.open(data['error'], '', {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                politeness: 'assertive',
                                duration: 3000
                            });
                            this.blockUI.stop();
                        }
                    }, err => {
                        this.blockUI.stop();
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.countryList.filter(option => option.text.toLowerCase().includes(filterValue));
    }

    private getCountryCodeByName(countryName): string {
        const filteredCountry = this.countryList.filter(option => {
            if (option.text === countryName) {
                return option.value;
            }
        });
        return filteredCountry.length > 0 ? filteredCountry[0].value : '';
    }

    private getCountryNameByCode(countryCode): string {
        const filteredCountry = this.countryList.filter(option => {
            if (option.value === countryCode) {
                return option;
            }
        });
        return filteredCountry.length > 0 ? filteredCountry[0].text : '';
    }
}
