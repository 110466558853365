import { Component, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Contact } from '../contact.model';
import { ContactsService } from '../contacts.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'contacts-contact-form-dialog',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactsContactFormDialogComponent implements OnDestroy {
    action: string;
    contact: Contact;
    contactForm: UntypedFormGroup;
    dialogTitle: string;
    isLoading: Boolean = false;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ContactsContactFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: UntypedFormBuilder,
        private _contactsService: ContactsService,
        private matSnackBar: MatSnackBar
    ) {
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Contact';
            this.contact = _data.contact;
        } else {
            this.dialogTitle = 'New Contact';
            this.contact = new Contact({});
        }

        this.contactForm = this.createContactForm();

        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): UntypedFormGroup {
        return this._formBuilder.group({
            id: [this.contact.id],
            name: [this.contact.name],
            avatar: [this.contact.avatar],
            email: [this.contact.email],
            contributor: [this.contact.contributor],
            isDeleted: [this.contact.isDeleted]
        });
    }

    save(): void {
        this._contactsService.updateContact(
            {
                userId: this.contactForm.controls.id.value, rawData: {
                    username: this.contactForm.controls.name.value,
                    email: this.contactForm.controls.email.value,
                }
            }).then(data => {
                if (data.success === 1) {
                    this.matDialogRef.close(['save', this.contactForm]);
                }
                else {
                    this.matSnackBar.open(data.error, '', {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        politeness: 'assertive',
                        duration: 3000
                    });
                }
            });
    }

    async resetPassword() {
        this.isLoading = true;
        const results = await this._contactsService.requestResetPassword(this.contactForm.controls.email.value);
        this.matSnackBar.open(results.data.message, '', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            politeness: 'assertive',
            duration: 3000
        });
        this.isLoading = false;
        if (results.data.success === 1) {
            this.matDialogRef.close(['save', this.contactForm]);
        }
    }
}
