import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {fuseAnimations} from '@fuse/animations';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ReportService} from '../../../../shared/services/report/report.service';
import {AppConstants} from '../../../../shared/constants';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ContactsService} from "../../../../shared/services/contacts/contacts.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-tag-popup',
    templateUrl: './tag-popup.component.html',
    styleUrls: ['./tag-popup.component.scss'],
    animations: fuseAnimations,
})
export class TagPopupComponent implements OnInit {
    postForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private contactService: ContactsService,
        private formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<TagPopupComponent>,
        private matSnackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
        this.postForm = this.formBuilder.group({
            title: [this.data?.content, Validators.required]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    SendReply(): void {
        this.submitted = true;
        this.loading = true;

        this.blockUI.start('Sharing...');

        const params = {
            content: this.f.title.value.trim(),
            id: this.data?._id
            // location: this.f.country.value,
        };
        this.contactService.createTag(params).subscribe((_data) => {
            this.submitted = false;
            this.loading = false;
            this.blockUI.stop();
            if (_data['success'] == 1) {
                if (this.data?._id) {
                    this.dialogRef.close({type: 'update', id: params.id, content: params.content});
                } else {
                    this.dialogRef.close({type: 'create', id: _data['data']._id, content: params.content});
                }
            } else {
                this.matSnackBar.open(_data['error'], '', {
                    verticalPosition: 'top',
                    politeness: 'assertive',
                    duration: 3000
                });
            }
        });
    }

    get f() {
        return this.postForm.controls;
    }

}
