import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {ReportService} from '../../../../shared/services/report/report.service';
import {AppConstants} from '../../../../shared/constants';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {TagPopupComponent} from "../tag-popup/tag-popup.component";
import {ContactsService} from "../../../../shared/services/contacts/contacts.service";

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

    param_selected = [];
    selected = [];
    rows: any[];
    loadingIndicator: boolean;
    reorderable: boolean;
    user: any = null;
    private _unsubscribeAll: Subject<any>;
    constructor(
        private contactServive: ContactsService,
        private _matDialog: MatDialog,
    ) {
        this._unsubscribeAll = new Subject();
        this.user = JSON.parse(localStorage.getItem(AppConstants.currentUser));
    }

    ngOnInit(): void {
        this.contactServive.getTagList().subscribe((data) => {
            this.rows = data;
        }, (err) => {
            console.log(err);
        });
    }

    onActivate(event): void {
        if (event.type === 'click' && event.cellIndex > 0) {
            const data = event.row;
            if (data){
                const dialogRef = this._matDialog.open(TagPopupComponent, { width: '600px', data: data});
                dialogRef.afterClosed().subscribe(d => {
                    if(d && d.type == 'update'){
                        this.rows = this.rows.filter(item => {
                            if(item._id == d.id){
                                item.content = d.content;
                            }
                            return item;
                        })
                        this.rows = [...this.rows]
                    }
                })
            }
        }
    }

    clear (type = ''){
        this.param_selected = [];
        let ids = 'all';

        if ( (type === '' && this.selected.length > 0) || type !== ''){
            this.selected.forEach((value) => {
                this.param_selected.push(value.content);
            });
            if (this.param_selected.length > 0) {
                ids = this.param_selected.join();
            }
            this.contactServive.removeTagList({ids: ids}).subscribe( (data) => {
                if (ids === 'all') {
                    this.rows = [];
                }
                else{
                    this.rows = this.rows.filter((item) => {
                        if (!ids.includes(item.content)){
                            return item;
                        }
                    });
                }
            });
        }
    }

    onSelect({ selected }) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    addTag() {
        const dialogRef = this._matDialog.open(TagPopupComponent, { width: '600px'});
        dialogRef.afterClosed().subscribe(data => {
            if(data && data.type == 'update'){
                this.rows = this.rows.filter(item => {
                    if(item._id == data.id){
                        item.content = data.content;
                    }
                    return item;
                })
                this.rows = [...this.rows]
            }
            if(data && data.type == 'create'){
                this.rows.unshift({
                    content: data.content,
                    _id: data.id
                })
                this.rows = [...this.rows];
            }
        })
    }
}
