import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import * as moment from "moment";


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommentsComponent {
  @Input() comments: any[];
  @Input() user: any;
  @Input() dialogClose: boolean;

  constructor(
      private router: Router,
  ) {

  }

  protected viewCommentDetail(comment) {
    comment.isdetail = true;
  }

  protected highlight(text) {
    return text ? text.replace(/(^|\s)(#[a-z\d-]+)/ig, '$1<a class=\'hash-tag\'>$2</a>') : '';
  }


  gotoUserPage(username) {
    if (this.dialogClose) {
      location.href = '/' + username;
    } else {
      this.router.navigate(['/' + username]);
    }
  }

  handleMissingImage(event: ErrorEvent) {
    (event.target as HTMLImageElement).src = 'https://images-test-cdn.newbienudes.com/m/media-nn-test/pp/nopic.jpg';
  }
  // tslint:disable-next-line:typedef
  formatPeriod(value) {
    let str: string = moment(value).fromNow();
    if (str.length > 3 && str.substr(str.length - 4) === 'ago') {
      str = str.substr(0, str.length - 4);
    }
    return str;
  }
}
