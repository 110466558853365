import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ReportService} from '../../../../shared/services/report/report.service';
import {SocketService} from 'app/shared/services/socket.service';
import {AppConstants} from '../../../../shared/constants';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {ReportDialogComponent} from '../report-dialog/report-dialog.component';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

    param_selected = [];
    selected = [];
    rows: any[];
    loadingIndicator: boolean;
    reorderable: boolean;
    user: any = null;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _reportService: ReportService,
        private _matDialog: MatDialog,
        private socketService: SocketService,
    ) {
        this._unsubscribeAll = new Subject();
        this.user = JSON.parse(localStorage.getItem(AppConstants.currentUser));
    }

    ngOnInit(): void {
        this._reportService.getAll().subscribe((data) => {
            this.rows = data;
        }, (err) => {
            console.log(err);
        });
        this.socketService.emitData('connecting', this.user.id);
    }

    onActivate(event): void {
        if (event.type === 'click' && event.cellIndex > 0) {
            const data = event.row;
            if (data) {
                this.rows = this.rows.filter((item) => {
                    if(item._id == data._id) {
                        item.adminview = true;
                    }
                    return item;
                })
                const dialogRef = this._matDialog.open(ReportDialogComponent, {width: '600px', data: {
                    report: data
                    }});
            }
        }
    }

    onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    clear(type = '') {
        if (this.selected.length === 0) {
            return;
        }
        if (!confirm('Are you sure to delete?')) {
            return;
        }
        this.param_selected = [];
        let ids = 'all';

        if ((type === '' && this.selected.length > 0) || type !== '') {
            this.selected.forEach((value) => {
                this.param_selected.push(value._id);
            });
            if (this.param_selected.length > 0) {
                ids = this.param_selected.join();
            }
            this._reportService.deleteReports({ids: ids}).subscribe((data) => {
                if (ids === 'all') {
                    this.rows = [];
                } else {
                    this.rows = this.rows.filter((item) => {
                        if (!ids.includes(item._id)) {
                            return item;
                        }
                    });
                }
            });
        }
    }
}
